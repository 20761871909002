import { FC, useEffect, useState } from 'react';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { Modal } from 'components/modals/common/modal';
import { AccountStatuses } from 'constants/account-statuses';
import iconSingleArrow from 'assets/images/icons/icon-single-arrow-grey.svg';
import { IUserPersonalInformation, IUserGroup } from 'types/user-info';
import { AccountStatusBadge } from 'pages/dashboard/components/customer-locations-item/components/account-status-badge';
import { InternalUserLayout } from 'layouts/internal-user-layout';
import {
  CustomerLocationEditSection,
  ICsrFormProps,
} from 'pages/dashboard/components/customer-locations-item/components/customer-location-edit-section';
import { useHideGlobalModalHandler, useSelectLocationUserModal } from 'hooks/use-global-modal';
import { DashboardQueryTypes } from 'constants/dashboard-query-types.enum';

import './csr-edit-user-info-mobile-modal.scss';

export interface ICsrEditUserInfoMobileModalProps extends ICsrFormProps {
  usersAtLocation: IUserPersonalInformation[];
  groupedUsersAtLocation: IUserGroup[];
  selectedUser: IUserPersonalInformation;
  onUserChange: (newUser: IUserPersonalInformation) => void;
  reopenHandler: () => void;
  accountAtLocation: IUserPersonalInformation;
  grouping?: DashboardQueryTypes;
}

export const CsrEditUserInfoMobileModal: FC<ICsrEditUserInfoMobileModalProps> = ({
  locationId,
  onCancel,
  usersAtLocation,
  groupedUsersAtLocation,
  selectedUser,
  onUserChange,
  reopenHandler,
  accountAtLocation,
  grouping,
}) => {
  const contentstackPath = 'modals.0.customer_location_item_edit_modal.0';
  const isUserOnlyOne = usersAtLocation.length === 1;
  const [userAtLocation, setUserAtLocation] = useState(selectedUser);

  const onUserSelect = (user: IUserPersonalInformation) => {
    setUserAtLocation(user);
    onUserChange(user);
  };

  const showSelectLocationUserModal = useSelectLocationUserModal();
  const hideGlobalModal = useHideGlobalModalHandler();

  useEffect(() => {
    setUserAtLocation(accountAtLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAtLocation.email]);

  return (
    <Modal
      size="medium"
      isOpen={true}
      parentClassName="csr-edit-user-info-mobile-modal-wrapper"
      className="csr-edit-user-info-mobile-modal"
      hideCloseButton
    >
      <InternalUserLayout>
        <header>
          <button type="button" className="back-button" onClick={onCancel}>
            <img src={iconSingleArrow} className="csr-edit-user-info-mobile-modal__back-icon" alt="back button icon" />
            <span className="csr-edit-user-info-mobile-modal__caption">
              <ContentstackText contentKey={`${contentstackPath}.mobile_ui.back_button_caption`} />
            </span>
          </button>

          <h1 className="csr-edit-user-info-mobile-modal__title">
            <ContentstackText contentKey={`${contentstackPath}.mobile_ui.modal_title`} />
          </h1>
          <div className="select-user-dropdown">
            <button
              className="location-select-user-button button-reset"
              disabled={isUserOnlyOne}
              onClick={() => {
                showSelectLocationUserModal({
                  defaultUser: userAtLocation,
                  groupedUsersAtLocation: groupedUsersAtLocation,
                  onUserSelect: (user) => {
                    onUserSelect(user);
                    hideGlobalModal();
                  },
                });
              }}
            >
              <div className="location-select-user-button__label">
                <span className="location-select-user-button__label-text">{`${userAtLocation.firstName} ${userAtLocation.lastName}`}</span>
                {userAtLocation &&
                  [AccountStatuses.LOCKED, AccountStatuses.UNVERIFIED].includes(userAtLocation.accountStatus) && (
                    <AccountStatusBadge accountStatus={userAtLocation.accountStatus} isShort />
                  )}
              </div>
              <div className="location-select-user-button__indicator">
                <span className="location-select-user-button__dropdown-icon" />
              </div>
            </button>
          </div>
        </header>
        <CustomerLocationEditSection
          accountAtLocation={userAtLocation}
          locationId={locationId}
          reopenCsrEditUserInfoModal={reopenHandler}
          onCancel={onCancel}
          grouping={grouping}
        />
      </InternalUserLayout>
    </Modal>
  );
};
